import {
  MultiWeb3,
  providerNames,
  providerEvents,
} from '@iexec/react-wallet-manager';
// import Debug from 'debug';
import { DEFAULT_CHAIN } from '../config';
import { loadObject, saveObject } from '../utils/localStorage';
import { getCurrentChainDetails } from './history';

// const debug = Debug('service:web3');

const previousState = loadObject('multiWeb3');

const { chainId } = getCurrentChainDetails();

export const multiWeb3 = new MultiWeb3({
  providers: [providerNames.METAMASK],
  defaultProvider: previousState?.provider,
  defaultChainId: chainId || DEFAULT_CHAIN,
});

multiWeb3.on(providerEvents.PROVIDER_CONNECTED, ({ provider }) => {
  saveObject('multiWeb3', { provider });
});
multiWeb3.on(providerEvents.PROVIDER_DISCONNECTED, () => {
  saveObject('multiWeb3', {});
});

export const getWeb3 = () => multiWeb3.getWeb3();
